import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { omit, pick } from 'lodash';

const SectionDarkRotated: React.FC<PropsWithChildren & HTMLAttributes<any>> = ({
  children,
  ...other
}) => {
  return (
    <div className="relative">
      <div
        className="bg-background high-contrast text-foreground"
        style={
          {
            ...(other.style ? other.style : {}),
          } as any
        }
        {...omit(other, 'style')}
      >
        <div
          style={{
            height: '40px',
            position: 'absolute',
            top: '-30px',
            width: '100%',
            transform: 'rotate(180deg)',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 25"
            preserveAspectRatio="none"
          >
            <path
              style={{ fill: 'hsl(var(--background))' }}
              d="M0,6V0h1000v25L0,6z"
            ></path>
          </svg>
        </div>
        <div className="my-5 py-20">{children}</div>
        <div
          style={{
            height: '40px',
            position: 'absolute',
            width: '100%',
            bottom: '-30px',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 25"
            preserveAspectRatio="none"
          >
            <path
              style={{ fill: 'hsl(var(--background))' }}
              d="M0,6V0h1000v25L0,6z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SectionDarkRotated;
