"use client";
import { PropsWithChildren, useRef, useState } from "react";
import { Slot } from "@radix-ui/react-slot";
import useScroll from "@/src/hooks/useScroll.ts";

export default function ScrollHighlight({ children }: PropsWithChildren) {
  const [scale, setScale] = useState(1);

  const offsetStartFromTop = 300;

  const onScroll = () => {
    
    if (!childElement.current?.getBoundingClientRect()) return;
    
    const topDiff = childElement.current?.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;

    if (
      Math.abs(windowHeight / 2 - topDiff) < offsetStartFromTop &&
      topDiff > 0
    ) {
      const centerDiff = Math.abs(topDiff - windowHeight / 2);
      const diffToInterest = Math.max(
        0,
        Math.abs(centerDiff - offsetStartFromTop) / offsetStartFromTop,
      );

      setScale(1 + Math.round(diffToInterest * 0.03 * 1000) / 1000);
    } else {
      setScale(1);
    }
  };

  useScroll(onScroll, 30);

  const childElement = useRef<HTMLDivElement>(null);

  return (
    <Slot
      style={
        {
          "--head-scale": scale,
          transform: `scale(var(--head-scale))`,
          transitionDuration: "300ms linear",
          transformOrigin: "center center",
        } as any
      }
      className="transition-transform"
      ref={childElement}
    >
      {children}
    </Slot>
  );
}
