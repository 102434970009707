"use client"

import Image, {ImageProps} from "next/image";
import { ReactEventHandler, useState } from 'react';

export default function StrapiMediaClient({blurDataURL, ...imageProps}: {
    blurDataURL: ImageProps['blurDataURL'],
} & ImageProps) {

    const [isLoaded, setIsLoaded] = useState(false);
    
    const onLoad: ReactEventHandler<HTMLImageElement> = (event) => {
        event.currentTarget.style.opacity = "100"
        setIsLoaded(true);
    }
    const computedStyles = isLoaded ? {opacity: "100"} : {};
    
    return <Image
        blurDataURL={blurDataURL}
        placeholder="blur"
        style={{
            transition: 'all .15s cubic-bezier(.4,0,.2,1)',
            opacity: "50",
            ...computedStyles,
        }}
        onLoad={onLoad}
        {...imageProps}
      />

}
