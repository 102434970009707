"use client";
import { useEffect } from "react";

type ContentVersionProps = {
  version: string;
};

export function ContentVersion({ version }: ContentVersionProps) {
  useEffect(() => {
    // @ts-ignore
    (window as any).contentVersion = version;
  }, [version]);

  return <></>;
}
