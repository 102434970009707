'use client';

import { Input } from '@/src/components/ui/input.tsx';
import { Button } from '@/src/components/ui/button.tsx';
import { Badge } from '@/src/components/ui/badge.tsx';
import { useLanguageContext } from '@/src/components/layout/ProvideLanguageContext.tsx';
import { useTranslations } from 'next-intl';
import { HTMLAttributes, useRef, useState } from 'react';
import { Search } from 'lucide-react';

export default function DiscoveryTeaser({
  samples,
  ...props
}: { samples: string[] } & HTMLAttributes<HTMLDivElement>) {
  const trans = useTranslations();

  const [isFilled, setFilled] = useState<boolean>(false);

  const { useRouter } = useLanguageContext();
  const router = useRouter();

  const inputRef = useRef<HTMLInputElement>(null);
  const submitSearch = () => {
    router.push(`/discovery/?search_text=${inputRef.current?.value}`);
  };
  const clickedSample = (sample: string) => {
    router.push(`/discovery/?search_text=${sample}`);
  };

  return (
    <div {...props}>
      <div className="w-full rounded-full bg-white px-4 py-2 text-black shadow-xl ring-1 ring-zinc-400">
        <div className="flex flex-row overflow-hidden rounded-2xl bg-white">
          <Input
            autoComplete="off"
            placeholder={trans('DISCOVERY.TEASER.DESCRIBE_MEDIA')}
            ref={inputRef}
            onKeyDown={(e) => {
              if (e.key == 'Enter') {
                submitSearch();
              }
            }}
            onChange={(e) => {
              setFilled((e.target as any).value != '');
            }}
            variant="unstyled"
          />

          <Button variant="colorful" onClick={submitSearch}>
            {trans('COMMON.SEARCH')}
          </Button>
        </div>
      </div>
      <div className="mt-3 flex flex-row flex-wrap items-center gap-3 text-black dark:text-white">
        <span className="text-foreground">Inspiration:</span>
        {samples.map((sample, i) => (
          <Badge
            key={i}
            onClick={() => clickedSample(sample)}
            size="lg"
            variant="secondary"
          >
            <Search className="mr-2" />
            {sample}
          </Badge>
        ))}
      </div>
    </div>
  );
}
