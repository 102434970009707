import { HTMLAttributes, PropsWithChildren } from 'react';
import { cn } from '@/src/lib/utils.ts';

export default function SectionDarkRect({
  children,
  className,
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div
      className={cn(
        className,
        'high-contrast relative flex w-full justify-center',
      )}
    >
      <div className="from-background to-background/85 prose-h1:font-bold w-full rounded-2xl bg-gradient-to-t px-8 py-10 text-white">
        {children}
      </div>
    </div>
  );
}
