import {
  ContentHeadingBigAttributes,
  ContentTwoColInfoAttributes,
  SectionAttributes,
} from '@/src/lib/strapi/strapi-model.ts';
import { cn } from '@/src/lib/utils';
import React, { PropsWithChildren } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import SectionDarkRotated from '@/src/components/layout/SectionDarkRotated.tsx';
import SectionDarkRect from '@/src/components/layout/SectionDarkRect.tsx';

const sectionVariants = cva('', {
  variants: {
    variant: {
      default: '',
    },
    spacing: {
      none: '',
      sm: 'my-20',
      md: 'my-40',
      md2x: 'my-80',
      lg: 'my-[60vh]',
    },
  },
  defaultVariants: {
    variant: 'default',
    spacing: 'none',
  },
});

function StrapiSection({
  children,
  attributes,
}: PropsWithChildren<{
  attributes: SectionAttributes;
}>) {
  let wrapping = <div className="container">{children}</div>;
  if (attributes.look === 'dark-rotated') {
    wrapping = (
      <SectionDarkRotated>
        <div className="container">{children}</div>
      </SectionDarkRotated>
    );
  } else if (attributes.look === 'dark-rect') {
    wrapping = (
      <div className="container">
        <SectionDarkRect>{children}</SectionDarkRect>
      </div>
    );
  }

  return (
    <div className={cn(sectionVariants(attributes as any))}>{wrapping}</div>
  );
}

export function canHaveSection(section?: SectionAttributes) {
  return (element: React.ReactElement) => {
    if (!section) {
      return <div className="container">{element}</div>;
    } else {
      return <StrapiSection attributes={section}>{element}</StrapiSection>;
    }
  };
}

export default StrapiSection;
